<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>list_alt</md-icon>
            <h3 class="pl-2 text-uppercase">Forms</h3>
          </div>
          <md-button
            class="bg-green text-white rounded-md md-dense m-0 text-uppercase"
            :to="{ name: 'form.create' }"
          >
            <md-icon class="text-white">add</md-icon> Create New
          </md-button>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-md-center justify-sm-center justify-xs-center">
          <div class="inline-flex flex-wrap justify-sm-center justify-xs-center">
            <SelectComponent class="w-64 m-2" />
            <SelectComponent class="w-64 m-2" />
          </div>
          <SearchBox class="w-64 p-2 m-2" />
        </div>
        <md-divider></md-divider>
        <Table :items="forms" @onSelect="onSelect">
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell
              md-numeric
              md-label="SL"
            >
              {{ item.id }}
            </md-table-cell>
            <md-table-cell
              class="text-victoria text-base"
              md-label="FORM_ID"
            >
              {{ item.formId }}
            </md-table-cell>
            <md-table-cell
              class="text-victoria text-base"
              md-label="BLOG INDEX"
            >
              {{ item.blogIndex }}
            </md-table-cell>
            <md-table-cell
              md-label="CREATED AT"
            >
              {{ item.createdAt }}
            </md-table-cell>
            <md-table-cell 
              class="text-victoria text-base"
              md-label="STATUS"
            >
              {{ item.status }}
            </md-table-cell>
          </template>
        </Table>
      </CardBody>
    </Card>
  </div>
</template>

<script>
import { Card, CardBody, SelectComponent, Table } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import data from "@/data/form/data";
import { mapMutations } from "vuex";

export default {
  name: "Form-List-View",
  components: {
    Card,
    CardBody,
    SelectComponent,
    SearchBox,
    Table,
  },
  data() {
    return {
      forms: data.forms,
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onSelect(item) {
      this.$router.push({
        name: "form.edit",
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>